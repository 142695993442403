.d-flex { display: flex; }
.flex-row            { flex-direction: row !important; }
.flex-column         { flex-direction: column !important; }
.flex-row-reverse    { flex-direction: row-reverse !important; }
.flex-column-reverse { flex-direction: column-reverse !important; }
.flex-wrap         { flex-wrap: wrap !important; }
.flex-nowrap       { flex-wrap: nowrap !important; }
.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }
.flex-fill         { flex: 1 1 auto !important; }
.flex-grow-0       { flex-grow: 0 !important; }
.flex-grow-1       { flex-grow: 1 !important; }
.flex-shrink-0     { flex-shrink: 0 !important; }
.flex-shrink-1     { flex-shrink: 1 !important; }
.justify-content-start   { justify-content: flex-start !important; }
.justify-content-end     { justify-content: flex-end !important; }
.justify-content-center  { justify-content: center !important; }
.justify-content-between { justify-content: space-between !important; }
.justify-content-around  { justify-content: space-around !important; }
.align-items-start    { align-items: flex-start !important; }
.align-items-end      { align-items: flex-end !important; }
.align-items-center   { align-items: center !important; }
.align-items-baseline { align-items: baseline !important; }
.align-items-stretch  { align-items: stretch !important; }
.align-content-start   { align-content: flex-start !important; }
.align-content-end     { align-content: flex-end !important; }
.align-content-center  { align-content: center !important; }
.align-content-between { align-content: space-between !important; }
.align-content-around  { align-content: space-around !important; }
.align-content-stretch { align-content: stretch !important; }
.align-self-auto     { align-self: auto !important; }
.align-self-start    { align-self: flex-start !important; }
.align-self-end      { align-self: flex-end !important; }
.align-self-center   { align-self: center !important; }
.align-self-baseline { align-self: baseline !important; }
.align-self-stretch  { align-self: stretch !important; }

.m-n { margin: 0!important; }
.m-1 { margin: 0.5rem!important; }
.m-2 { margin: 1.0rem!important; }
.m-3 { margin: 1.5rem!important; }
.m-4 { margin: 2.0rem!important; }
.m-5 { margin: 2.5rem!important; }
.mr-n { margin-right: 0!important; }
.mr-1 { margin-right: 0.5rem!important; }
.mr-2 { margin-right: 1.0rem!important; }
.mr-3 { margin-right: 1.5rem!important; }
.mr-4 { margin-right: 2.0rem!important; }
.mr-5 { margin-right: 2.5rem!important; }
.ml-n { margin-left: 0!important; }
.ml-1 { margin-left: 0.5rem!important; }
.ml-2 { margin-left: 1.0rem!important; }
.ml-3 { margin-left: 1.5rem!important; }
.ml-4 { margin-left: 2.0rem!important; }
.ml-5 { margin-left: 2.5rem!important; }
.mh-n { margin-right: 0!important; margin-left: 0!important; }
.mh-1 { margin-right: 0.5rem!important; margin-left: 0.5rem!important; }
.mh-2 { margin-right: 1.0rem!important; margin-left: 1.0rem!important; }
.mh-3 { margin-right: 1.5rem!important; margin-left: 1.5rem!important; }
.mh-4 { margin-right: 2.0rem!important; margin-left: 2.0rem!important; }
.mh-5 { margin-right: 2.5rem!important; margin-left: 2.5rem!important; }
.mb-n { margin-bottom: 0!important; }
.mb-1 { margin-bottom: 0.5rem!important; }
.mb-2 { margin-bottom: 1.0rem!important; }
.mb-3 { margin-bottom: 1.5rem!important; }
.mb-4 { margin-bottom: 2.0rem!important; }
.mb-5 { margin-bottom: 2.5rem!important; }
.mt-n { margin-top: 0!important; }
.mt-1 { margin-top: 0.5rem!important; }
.mt-2 { margin-top: 1.0rem!important; }
.mt-3 { margin-top: 1.5rem!important; }
.mt-4 { margin-top: 2.0rem!important; }
.mt-5 { margin-top: 2.5rem!important; }
.mv-n { margin-bottom: 0!important; margin-top: 0!important; }
.mv-1 { margin-bottom: 0.5rem!important; margin-top: 0.5rem!important; }
.mv-2 { margin-bottom: 1.0rem!important; margin-top: 1.0rem!important; }
.mv-3 { margin-bottom: 1.5rem!important; margin-top: 1.5rem!important; }
.mv-4 { margin-bottom: 2.0rem!important; margin-top: 2.0rem!important; }
.mv-5 { margin-bottom: 2.5rem!important; margin-top: 2.5rem!important; }

.p-n { padding: 0!important; }
.p-1 { padding: 0.5rem!important; }
.p-2 { padding: 1.0rem!important; }
.p-3 { padding: 1.5rem!important; }
.p-4 { padding: 2.0rem!important; }
.p-5 { padding: 2.5rem!important; }
.pr-n { padding-right: 0!important; }
.pr-1 { padding-right: 0.5rem!important; }
.pr-2 { padding-right: 1.0rem!important; }
.pr-3 { padding-right: 1.5rem!important; }
.pr-4 { padding-right: 2.0rem!important; }
.pr-5 { padding-right: 2.5rem!important; }
.pl-n { padding-left: 0!important; }
.pl-1 { padding-left: 0.5rem!important; }
.pl-2 { padding-left: 1.0rem!important; }
.pl-3 { padding-left: 1.5rem!important; }
.pl-4 { padding-left: 2.0rem!important; }
.pl-5 { padding-left: 2.5rem!important; }
.ph-n { padding-right: 0!important; padding-left: 0!important; }
.ph-1 { padding-right: 0.5rem!important; padding-left: 0.5rem!important; }
.ph-2 { padding-right: 1.0rem!important; padding-left: 1.0rem!important; }
.ph-3 { padding-right: 1.5rem!important; padding-left: 1.5rem!important; }
.ph-4 { padding-right: 2.0rem!important; padding-left: 2.0rem!important; }
.ph-5 { padding-right: 2.5rem!important; padding-left: 2.5rem!important; }
.pb-n { padding-bottom: 0!important; }
.pb-1 { padding-bottom: 0.5rem!important; }
.pb-2 { padding-bottom: 1.0rem!important; }
.pb-3 { padding-bottom: 1.5rem!important; }
.pb-4 { padding-bottom: 2.0rem!important; }
.pb-5 { padding-bottom: 2.5rem!important; }
.pt-n { padding-top: 0!important; }
.pt-1 { padding-top: 0.5rem!important; }
.pt-2 { padding-top: 1.0rem!important; }
.pt-3 { padding-top: 1.5rem!important; }
.pt-4 { padding-top: 2.0rem!important; }
.pt-5 { padding-top: 2.5rem!important; }
.pv-n { padding-bottom: 0!important; padding-top: 0!important; }
.pv-1 { padding-bottom: 0.5rem!important; padding-top: 0.5rem!important; }
.pv-2 { padding-bottom: 1.0rem!important; padding-top: 1.0rem!important; }
.pv-3 { padding-bottom: 1.5rem!important; padding-top: 1.5rem!important; }
.pv-4 { padding-bottom: 2.0rem!important; padding-top: 2.0rem!important; }
.pv-5 { padding-bottom: 2.5rem!important; padding-top: 2.5rem!important; }

.vh-25 { height: 25vh!important; }
.vh-50 { height: 50vh!important; }
.vh-75 { height: 75vh!important; }
.vh-100 { height: 100vh!important; }
.vw-25 { width: 25vw!important; }
.vw-50 { width: 50vw!important; }
.vw-75 { width: 75vw!important; }
.vw-100 { width: 100vw!important; }

.h-25 { height: 25%!important; }
.h-50 { height: 50%!important; }
.h-75 { height: 75%!important; }
.h-100 { height: 100%!important; }
.w-25 { width: 25%!important; }
.w-50 { width: 50%!important; }
.w-75 { width: 75%!important; }
.w-100 { width: 100%!important; }